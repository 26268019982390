body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-image: url(imgs/background-1.png);
    background-size: cover;
}


input,
button {
  font: inherit;
  box-sizing: border-box;
}

input[type='submit'],
button {
  cursor: pointer;
}

.App {
  margin: 0 auto;
  max-width: 1000px;
  width: 95%;
}

.App h1:before {
  content: '🏆';
  margin-right: 0.5em;
}

.AddWin {
  display: flex;
  flex-wrap: wrap;
}

.AddWin input {
  padding: 1.5rem;
  border: 1px solid #666;
  border-radius: 3px;
}

.searchBar {
    width: 75%;
}

.AddWin input[type='text'] {
  flex-grow: 1;
  margin-right: 0.5rem;
}

.wins {
    list-style-type: none;
    /*padding: 100px;*/
    background-color: #fff;
    width: 95%;
    max-width: 1000px;
    overflow: hidden;
    position: absolute;
    top: 170px;
    bottom: 20px;
    box-sizing: border-box;
}

.Win {
    padding: 15px;
    /*margin: 10px; */
    border-radius: 3px;
    width: 130px;
    height: 130px;
    border-radius: 100px;
    text-align: center;
    position: absolute;
    box-sizing: border-box;
}

.winText {
    margin-top: 30px;
}

.Win .remove-button {
  font-size: 80%;
  float: right;
  border: 0;
  border-radius: 3px;
  background: transparent;
}

.Win .remove-button:hover {
  color: #fff;
  background-color: #000;
}

.winSuggestion {
    width: 100px;
    height: 100px;
    background-color: #fff;
    float: left;
    margin: 10px;
    padding: 7px;
    border-radius: 7px;
}

.winSuggestion:hover {
    cursor: pointer;
}

.dropdown {
    background-color: lightgray;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 999;
    margin-top: 68px;
    width: 480px;
}

.dropdownRow {
    margin: 2px 0;
    border-bottom: 1px solid gray;
    cursor: pointer;
}

.searchContainer {
    width: 100%;
}

.suggestionsTitle {
    border-bottom: 1px solid gray;
}





